"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.endpointAliases = void 0;
exports.endpointAliases = {
    getStoreData: ["get", "/{facilityId}/store", true],
    getPrices: ["get", "/{facilityId}/pricing/{productId}", true],
    createBooking: ["post", "/{facilityId}/book", true],
    updateStoreBooking: ["put", "/{facilityId}/booking/{bookingId}", true],
    createPaymentIntent: ["post", "/{facilityId}/create-payment-intent", true],
    getRedsysForm: ["post", "/{facilityId}/redsys-form", true],
    getAvailabilityForStore: ["get", "/{facilityId}/availability", true],
    trackActivity: ["post", "/{facilityId}/activity", true],
    proxyStore: ["get", "/{facilityId}/proxy", true],
};
